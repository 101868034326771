import * as React from "react"
import { Link } from "gatsby"
// @ts-ignore
import logo from "../img/mopfloorcheck-logo.png"
import { Helmet } from "react-helmet"

export default (props: { tab: 'home' | 'who' | 'services' | 'contact' }) => {
  return (
    <header className="site-header">
      <Helmet>
        {/* Some common meta tags used across the site */}
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width" />
      </Helmet>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="MOP Floorcheck" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className={`nav-link ${props.tab === 'home' ? ' active' : ''}`} aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.tab === 'who' ? ' active' : ''}`} to="/who-we-are">Who</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.tab === 'services' ? ' active' : ''}`} to="/services">Services</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.tab === 'contact' ? ' active' : ''}`} to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}